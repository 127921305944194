<template>
  <div class="profile">
    <div class="info-table">
      <div class="info-item">
        <p class="title">Basic Information</p>
      </div>
      <div class="info-item">
        <div class="avatar">
          <img :src="userInfo.avatarUrl" />
          <el-icon class="uploader">
            <edit />
            <input type="file" name="file" class="upload-btn" accept="image/jpg,image/png,image/jpeg"
              @change="onSelectedAvatar" />
          </el-icon>
        </div>
      </div>
      <div class="info-item">
        <div>
          <label for="name">
            <p>Username</p>
          </label>
          <input type="text" v-model="updatedInfo.username" id="name" required />
        </div>
        <div>
          <label for="email">
            <p>Email</p>
          </label>
          <input type="text" v-model="updatedInfo.email" id="email" class="disabled-item" disabled />
        </div>
      </div>
      <div class="info-item">
        <div>
          <label for="institution">
            <p>Institution</p>
          </label>
          <input type="text" v-model="updatedInfo.institution" id="institution" />
        </div>
        <div>
          <label for="phone">
            <p>Phone</p>
          </label>
          <input type="text" v-model="updatedInfo.phone" id="phone" />
        </div>
      </div>
      <div class="info-item">
        <div>
          <label for="country">
            <p>Country</p>
          </label>
          <input type="text" v-model="updatedInfo.country" id="country" />
        </div>
        <div>
          <label for="position">
            <p>Position</p>
          </label>
          <input type="text" v-model="updatedInfo.position" id="position" />
        </div>
      </div>
      <div class="info-item">
        <button class="update-btn" @click="updateInfo">Update Information</button>
        <button class="update-btn" @click="onChangePwd">Change Password</button>
      </div>
    </div>
    <div class="info-table ccount-info">
      <div class="info-item">
        <p class="title">Account Information</p>
      </div>
      <div class="account-infos">
        <div class="account-item">
          Selection Times: {{ userInfo.serviceSelectionTimes }}
        </div>
        <div class="account-item">
          Prediction Times: {{ userInfo.servicePredictTimes }}
        </div>
        <div class="account-item">
          Generation Times: {{ userInfo.serviceGenerateTimes }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userApi } from "../../api/index.js";

export default {
  name: "profile",
  data() {
    return {
      userInfo: {},
      updatedInfo: {},
    };
  },
  computed: {
    infos() {
      return {
        "Username": this.userInfo.username,
        "Email": this.userInfo.email,
        "Institution": this.userInfo.institution,
        "Phone": this.userInfo.phone,
        "Country": this.userInfo.country,
        "Position": this.userInfo.position,
      }
    }
  },
  methods: {
    // 上传头像
    onSelectedAvatar(e) {
      const imgs = e.target.files;
      if (imgs && imgs[0]) {
        const img = imgs[0];
        // 判断图片大小
        if (img.size > 1024 * 1024 * 3) {
          console.log("Img size larger than 3M!");
        }
        // 转为FormData
        const formData = new FormData();
        formData.append("img", img);

        // 发出请求
        userApi
          .uploadAvatar(formData)
          .then((res) => {
            // 成功
            if (res.code == 200) {
              console.log(res.data);
              // 更新缓存
              this.$store.commit("setAvatar", res.data);
              this.userInfo.avatarUrl = this.$store.getters.getAvatar;
              this.$store.commit("alertMsg", {
                msg: "Sucessfully updated your avatar!",
                type: "ok",
              });
            } else if (res.code == 5000) {
              this.$store.commit("alertMsg", {
                msg: "Unsupported image format! Try with jpg/jpeg/png.",
                type: "error",
              });
            } else {
              this.$store.commit("alertMsg", {
                msg: "Fail to update your avatar! Try again",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$store.commit("alertMsg", {
              msg: err,
              type: "error",
            });
          });
      }
    },
    // 信息更新
    updateInfo() {
      // 获取已经修改的数据
      const data = {};
      let changeCnt = 0;
      if (this.updatedInfo.institution != this.userInfo.institution) {
        data["institution"] = this.updatedInfo.institution;
        changeCnt++;
      }
      if (this.updatedInfo.phone != this.userInfo.phone) {
        data["phone"] = this.updatedInfo.phone;
        changeCnt++;
      }
      if (this.updatedInfo.position != this.userInfo.position) {
        data["position"] = this.updatedInfo.position;
        changeCnt++;
      }
      if (this.updatedInfo.country != this.userInfo.country) {
        data["country"] = this.updatedInfo.country;
        changeCnt++;
      }
      if (this.updatedInfo.username != this.userInfo.username) {
        data["username"] = this.updatedInfo.username;
        changeCnt++;
      }
      //  没有信息被修改
      if (changeCnt == 0) {
        this.$store.commit("alertMsg", {
          msg: "Nothing changes!",
          type: "error",
        });
        return;
      }
      // 发出请求
      userApi
        .upadteInfo(this.userInfo.username, data)
        .then((res) => {
          // 请求成功
          if (res.code == 200) {
            // 请求更新好的用户信息
            userApi
              .getInfo(this.$store.state.token)
              .then((res2) => {
                if (res2.code == 200) {
                  this.$store.commit("setInfo", res2.data);
                  this.userInfo = res2.data;
                  this.$store.commit("alertMsg", {
                    msg: "Sucessfully updated information.",
                    type: "ok",
                  });
                } else {
                  this.userInfo = this.$store.getters.getInfo;
                  if (res.code == 401) {
                    this.$store.commit("alertMsg", {
                      msg: "Please login first!",
                      type: "error",
                    });
                    this.$router.push({
                      path: "/account/sign-in",
                      query: { redirect: this.$route.path },
                    });
                  } else if (res.code == 403) {
                    this.$store.commit("alertMsg", {
                      msg: "Unauthorized!",
                      type: "error",
                    });
                  } else if (res.code == 404) {
                    this.$store.commit("alertMsg", {
                      msg: "User is not exist!",
                      type: "error",
                    });
                  }
                }
              })
              .catch((err) => {
                this.$store.commit("alertMsg", {
                  msg: err,
                  type: "error",
                });
              });
          }
          // 未授权或未登录
          else if (res.code == 401) {
            this.$store.commit("alertMsg", {
              msg: "Please login first!",
              type: "error",
            });
            this.$router.push({
              path: "/account/sign-in",
              query: { redirect: this.$route.path },
            });
          }
          // 用户名重复
          else if (res.code == 1001) {
            this.$store.commit("alertMsg", {
              msg: "Username is not available!",
              type: "error",
            });
          }
          // 权限不够
          else {
            this.$store.commit("alertMsg", {
              msg: "Unauthorized!",
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
    onChangePwd() {
      this.$router.push({ path: "/user/change-pwd" });
    },
  },
  mounted() {
    this.userInfo = this.$store.getters.getInfo;
    // this.updatedInfo = this.userInfo; ？？ 浅拷贝出问题
    this.updatedInfo = JSON.parse(JSON.stringify(this.userInfo));
  },
};
</script>

<style scoped>
.profile {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.account-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.info-table {
  width: 100%;
  max-width: 2000px;
  list-style-type: none;
  margin-bottom: 50px;
  /* background: red; */
  border-radius: 10px;
}

.info-item:nth-child(odd) {
  background: var(--color2-dark);
}

.info-item:nth-child(even) {
  background: var(--color2-light);
}

.info-item {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-around;
  height: fit-content;
  padding: 10px 0;
}


.title {
  white-space: nowrap;
  text-align: start;
  justify-self: flex-start;
  border-radius: 5px;
  font-size: 25px;
  width: 100%;
  padding: 10px;
  color: black;
  font-weight: bolder;
}

.avatar {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  margin: 20px 0px;
  overflow: hidden;
  object-fit: cover;
  width: 150px;
  height: 150px;
  position: relative;
}


.update-btn {
  font-size: 20px;
  background: var(--color2-dark);
  padding: 10px;
  border: none;
  width: fit-content;
  cursor: pointer;
  border-radius: 10px;
  margin: 10px 0;
}



.avatar img {
  width: 150px;
  height: 150px;
}

.uploader {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  margin: 0;
  color: white;
  font-size: 50px;
}

.avatar:hover .uploader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  /* background: pink; */
}


.info-item div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
}

.info-item label {
  color: var(--color1-dark);
  font-size: 20px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.info-item input {
  flex: 1;
  /* height: 50px; */
  font-size: 20px;
  background: none;
  padding: 10px 0;
  padding-left: 8px;
  border: none;
  border-bottom: 1px solid var(--color1-dark);
  color: var(--color1-dark);
}

.info-item input:focus {
  border-bottom: 2px solid var(--color1-dark);
  /* /* border-radius: 8px; */
  outline: none;
}

/* .info-item input.disabled-item {
  color: #aaa;
} */

.update-btn:active {
  background: var(--color2-dark);
}

.account-infos {
  display: flex;
  width: 100%;
  color: black;
  background-color: var(--color2-light);
  justify-content: space-evenly;
}

.account-item {
  /* background: red; */
  font-size: 20px;
  margin: 20px 0;
}
</style>
